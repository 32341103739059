@mixin debug() {
	body::before {
		content: "xs";
		position: fixed;
		top: 0;
		right: 0;
		padding-left: 1px;
		padding-bottom: 1px;
		border-bottom-left-radius: 2px;
		z-index: 9999999;
		background-color: #000;
		color: #fff;

		@include media-breakpoint-up('sm') {
			content: "sm";
		}

		@include media-breakpoint-up('md') {
			content: "md";
		}

		@include media-breakpoint-up('lg') {
			content: "lg";
		}
	}
}