:root {
	--input-padding-x: 1.5rem;
	--input-padding-y: .75rem;
}

html {
	position: relative;
	min-height: 100%;
}

body {
	margin-bottom: 70px;
	padding-top: 65px;
}

label {
	display: inherit;
	width: auto;
}

a {
	.nounderline {
		text-decoration: none;
	}
}

p {
	padding-top:3px;
	padding-bottom:3px;
	margin-top:0px;
	margin-bottom:0px;
}

fieldset {
	margin-top: 10px;

	@include media-breakpoint-up('md') {
		&.small {
			width:550px;
		}
	}
}

legend {
	font-weight:bold;
}

img {
	&.logtypeimage {
		width:22px;
		min-height:22px;
		height:auto;
	}
}

.button {
	display: inline-block;
	vertical-align: top;
	width: 200px;
	height: 20px;
	padding: 10px;

	@include media-breakpoint-up('md') {
		margin-top:10px;
		border-radius:25px 25px 0px 0px;
	}

	@include media-breakpoint-down('md') {
		margin: 2px;
		border-radius: 25px;
	}
}

div {
	&.settings {
		vertical-align: top;
		padding-top: 3px;
		padding-bottom: 3px;
		margin-top: 0px;
		margin-bottom: 0px;
		display: inline-block;

		@include media-breakpoint-up('md') {
			width: 70%;
		}

		@include media-breakpoint-down('md') {
			width: 95%;
		}
	}

	&.functionssettings {
		padding-top:3px;
		padding-bottom:3px;
		margin-top:0px;
		margin-bottom:0px;
	}

	&.settingsright {
		padding-top: 3px;
		padding-bottom: 3px;
		margin-top: 0px;
		margin-bottom: 0px;
		display: inline-block;
	}

	&.settingsright_topspacer {
		padding-top:10px;
		padding-bottom:3px;
		margin-top:0px;
		margin-bottom:0px;
		display:inline-block;
	}
}

textarea {
	&.big {
		width: 95%;
	}

	@include media-breakpoint-up('md') {
		.small {
			width: 25%;
			min-width: 200px;
		}

		.fixed {
			width: 200px;
		}
	}

	@include media-breakpoint-down('md') {
		.small {
			width: 95%;
		}

		.fixed {
			width: 95%;
		}
	}
}

span {
	&.build {
		font-size: 10pt;

		@include media-breakpoint-down('xs') {
			display: none;
		}
	}
}

#plusminusbutton {
	@include media-breakpoint-up('md') {
		display: none;
	}
}

.hidden_midsizescreen {
	@include media-breakpoint-down('md') {
		display: none;
	}
}

.hidden_smallscreen {
	@include media-breakpoint-down('sm') {
		display: none;
	}
}

.hidden_verysmallscreen {
	@include media-breakpoint-down('xs') {
		display: none;
	}
}

.title {
	text-align:left;
	padding-left:5px;
	padding-right:2px;
	padding-top:2px;
	padding-bottom:2px;
	white-space:nowrap;

	@include media-breakpoint-down('xs') {
		font-size: 12pt;
	}

	@include media-breakpoint-up('xs') {
		font-size: 14pt;
	}
}

.hidden {
	display:none;
}

.nospacer {
	margin:0px;
	padding:0px;
	border:0px;
}

.div_addfunction_title {
	font-weight:bold;
	padding:5px;
}

.top {
	vertical-align:top;
}

.middle {
	vertical-align:middle;
}

.topspacer {
	padding-top:10px;
}

.rightspacer {
	padding-right:10px;
}

.submitspacer {
	margin-bottom:5px;
	margin-top:5px;
	margin-left:20px;
	margin-right:20px;
}

.spacer_topnbottom {
	padding-top:10px;
	padding-bottom:50px;
}

.botmanagertitle {
	text-align:left;
	font-size:20pt;
}

.botmanagerdescription {
	text-align:left;
	font-weight:bold;
	padding-top:5px;
	padding-bottom:10px;
}

.logviewpages {
	text-align:center;
	padding-top:15px;
}

.logdelete {
	text-align:center;
	padding-top:15px;
}

.div_addfunction {
	width:90%;
	text-align:center;
	padding-top:10px;
	margin-left:auto;
	margin-right:auto;
}

#languagename {
	font-weight:bold;
}

.rightpadding {
	padding-right:5px;
}

.leftrightpadding {
	padding-left:5px;
	padding-right:5px;
}

.bottommargin {
	margin-bottom:20px;
}

.nopaddingmargin {
	padding:0px;
	margin:0px;
}

.hand {
	cursor:pointer;
}

.ellipsis {
	white-space:nowrap;
	text-overflow:ellipsis;
	overflow:hidden;
}

.nowrap {
	white-space:nowrap;
}

.bold {
	font-weight:bold;
}

.left {
	text-align:left;
}

.center {
	text-align:center;
}

.right {
	text-align:right;
}

.languageimagechooser {
	padding:1px;
}

.languageimagechooser:hover {
	padding:0px;
	border-style:solid;
	border-width:1px;
}

table.bbcode {
	padding:0px;
	padding-bottom:1px;
}

select.bbcode {
	height:100%;
}

table.bbcode, select.bbcode {
	border:0;
	outline:none;
}

.errormessage {
	font-weight:bold;
}

.botmanagerwarning {
	text-align:left;
	font-weight:bold;
	padding-top:0px;
	padding-bottom:20px;
}

hr.borderline {
	border: none;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.borderline {
	border-bottom-width:1px;
	border-bottom-style:solid;
}

.dropdown-toggle::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: 0;
	@include fa_content('053');
	border: 0 !important;
	-webkit-transition: 1s ease-in-out;
	-moz-transition: 1s ease-in-out;
	-o-transition: 1s ease-in-out;
	transition: 1s ease-in-out;
}

.dropdown-toggle:hover::after,
.dropdown-toggle:focus::after,
.dropdown-toggle:active::after{
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.fa-spin-hover:hover .fa-sync {
	-webkit-animation: fa-spin 2s infinite linear;
	-moz-animation: fa-spin 2s infinite linear;
	-o-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear;
}

.icon-user.red {
	color: #B40404;
}

.icon-user.green {
	color: #088A08;
}

.icon-user.gray {
	color: #6e6e6e;
}

.icon-user {
	color: #6e6e6e;
}

.icon-status {
	color: #6e6e6e;
}

.icon-status.green {
	color: #088A08;
}

.icon-status.red {
	color: #B40404;
}

.tooltip-inner {
	max-width: 300px;
}

.modal {
	color: #1b1e21;
}

.bootui_logo {
	max-width: 250px;
	height: auto;
}

.bootui_search {
	.search-query {
		padding-right: 4px;
		padding-left: 4px;
		margin-bottom: 0;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
	}

	button {
		height: 38px;
		width: 38px;
		float: right;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		background: transparent;
		border-color: transparent;
		text-decoration:none;
		margin-top: -38px;
		transition: color 0.4s linear;
		position: relative;
		z-index: 99;

		&:hover {
			color: #fff;
		}
	}

	.search-query:focus + button {
		color: #3b4248;
	}
}




// OLD STUFF
#loadingscreen, #loadingscreencommand
{
	margin-top:10px;
	padding:10px;
	background-color:#EEE;
	text-align:center;
	font-weight:bold;
	color:#33D;
}

#helpinstancelistreload
{
	margin-top:10px;
	padding:10px;
	background-color:#EEE;
}

#statusscreen, #botstatusscreen
{
	margin-top:10px;
	padding:10px;
	background-color:#EEE;
	text-align:center;
	font-weight:bold;
	color:#D33;
}