.wizard {
	margin: 20px auto;
	background: #fff;

	@include media-breakpoint-down('sm') {
		width: 90%;
		height: auto !important;
	}

	.nav-tabs {
		position: relative;
		margin: 40px auto;
		margin-bottom: 0;
		border-bottom-color: #e0e0e0;

		> li {
			width: 20%;

			&.active {
				> a,
				> a:hover,
				> a:focus {
					color: #555555;
					cursor: default;
					border: 0;
					border-bottom-color: transparent;
				}
			}

			a {
				width: 70px;
				height: 70px;
				margin: 20px auto;
				border-radius: 100%;
				padding: 0;
				position: relative;

				@include media-breakpoint-down('sm') {
					width: 50px;
					height: 50px;
					line-height: 50px;
				}

				&:hover {
					background: transparent;
				}
			}
		}
	}

	> .wizard-inner {
		position: relative;

		.connecting-line {
			height: 2px;
			background: #e0e0e0;
			position: absolute;
			width: 80%;
			margin: 0 auto;
			left: 0;
			right: 0;
			top: 50%;
			z-index: 1;
		}
	}

	span {
		&.round-tab {
			width: 70px;
			height: 70px;
			line-height: 70px;
			display: inline-block;
			border-radius: 100px;
			background: #fff;
			border: 2px solid #e0e0e0;
			z-index: 2;
			position: absolute;
			left: 0;
			text-align: center;
			font-size: 25px;

			@include media-breakpoint-down('sm') {
				font-size: 16px;
				width: 50px;
				height: 50px;
				line-height: 50px;
			}

			&:hover {
				color: #333;
				border: 2px solid #333;
			}

			i {
				color: #555;
			}
		}
	}

	li {
		a {
			&.active {
				span {
					&.round-tab {
						background: #fff;
						border: 2px solid #5bc0de;

						i {
							color: #5bc0de;
						}
					}
				}
			}

			&.success {
				span {
					&.round-tab {
						background: #fff;
						border: 2px solid $color-success-1;

						i {
							color: $color-success-1;
						}
					}
				}
			}

			&::after {
				content: " ";
				position: relative;
				left: 46%;
				top: -20px;
				opacity: 0;
				margin: 0 auto;
				bottom: 0px;
				border: 5px solid transparent;
				border-bottom-color: #5bc0de;
				transition: 0.1s ease-in-out;
			}
		}

		&.active {
			&.nav-item {
				&::after {
					content: " ";
					position: relative;
					left: 46%;
					top: -20px;
					opacity: 1;
					margin: 0 auto;
					bottom: 0px;
					border: 10px solid transparent;
					border-bottom-color: #5bc0de;
				}
			}

			&::after {
				@include media-breakpoint-down('sm') {
					content: " ";
					position: absolute;
					left: 35%;
				}
			}
		}
	}

	.tab-pane {
		position: relative;
		padding-top: 50px;
	}

	h3 {
		margin-top: 0;
	}
}