@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	-ms-transform: rotate($degrees);
	-o-transform: rotate($degrees);
	transform: rotate($degrees);
}

@mixin transition($transition...) {
	@if $enable-transitions {
		@if length($transition) == 0 {
			transition: $transition-base;
		} @else {
			transition: $transition;
		}
	}

	@media screen and (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

@mixin box-shadow($shadow...) {
	@if $enable-shadows {
		box-shadow: $shadow;
	}
}
