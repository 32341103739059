.card-signin {
	border: 0;
	border-radius: 1rem;
	box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);

	.card-title {
		margin-bottom: 2rem;
		font-weight: 300;
		font-size: 1.5rem;
	}

	.card-body {
		padding: 2rem;
	}
}

.form-signin {
	width: 100%;

	.btn {
		font-size: 80%;
		border-radius: 5rem;
		letter-spacing: .1rem;
		font-weight: bold;
		padding: 1rem;
		transition: all 0.2s;
	}

	.form-label-group {
		position: relative;
		margin-bottom: 1rem;

		input {
			height: auto;
			border-radius: 2rem;

			&::-webkit-input-placeholder {
				color: transparent;
			}

			&::-ms-input-placeholder {
				color: transparent;
			}

			&::-moz-placeholder {
				color: transparent;
			}

			&::placeholder {
				color: transparent;
			}

			&:not(:placeholder-shown) {
				padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
				padding-bottom: calc(var(--input-padding-y) / 3);

				~label {
					padding-top: calc(var(--input-padding-y) / 3);
					padding-bottom: calc(var(--input-padding-y) / 3);
					font-size: 12px;
					color: #777;
				}
			}
		}

		> label {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			margin-bottom: 0;
			/* Override default `<label>` margin */
			line-height: 1.5;
			color: #495057;
			border: 1px solid transparent;
			border-radius: .25rem;
			transition: all .1s ease-in-out;
		}

		> input, > label {
			padding: var(--input-padding-y) var(--input-padding-x);
		}
	}
}