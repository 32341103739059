.loading {
	position: fixed;
	z-index: 999999;
	height: 2em;
	width: 2em;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	&::before {
		content: '';
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
		background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
	}

	&:not(:required) {
		/* hide "loading..." text */
		font: 0/0 a;
		color: transparent;
		text-shadow: none;
		background-color: transparent;
		border: 0;

		&:after {
			animation: fa-spin 1s infinite steps(8);
			@include fa_content('110');
			color: #fff;
			display: block;
			font-size: 2rem;
			width: 1em;
			margin-top: -0.5em;
		}
	}
}

.better-spin {
	animation: fa-spin 1s infinite steps(8);
}