/**
 * Variables
 */


// DEBUG
$enable-debug:								false;

// Enable Shadows
$enable-shadows:							false;

// Theme Colors
$theme-color-1:								#51A2E0;
$theme-color-2:								#488FC7;
$theme-color-3:								#3D79A8;
$theme-color-4:								#31658C;
$theme-color-5:								#404040;

// Variables


// Mixins
$cursor-disabled:							not-allowed;
$enable-rounded:							false;
$enable-transitions:						true;
$enable-shadows:							false;



// Toastr
$toast-bg-color:							#1d89cf;
$toast-info-bg-color:						#1d89cf;
$toast-success-bg-color:					#00a65a;
$toast-error-bg-color:						#dd4b39;
$toast-warning-bg-color:					#f39c12;




// Fonts
$font-awesome:								'Font Awesome 5 Free';
$fa-font-path:       						"../fonts" !default;
$font-family-base:							'Lato', sans-serif;
$font-family-secondary:						'Raleway', sans-serif;
$font-size-base:							0.875rem;
$font-size-lg:								1.118rem;
$font-size-sm:								0.688rem;
$font-size-xs:								0.563rem;
$icon-font-size:							13px;
$text-color:								#fff;






// Paddings
$padding-base-vertical:						7px;
$padding-base-horizontal:					12px;

$padding-large-vertical:					9px;
$padding-large-horizontal:					15px;

$padding-small-vertical:					6px;
$padding-small-horizontal:					11px;

$padding-xs-vertical:						5px;
$padding-xs-horizontal:						0px;

$content-padding-large:						20px;
$content-padding-base:						15px;
$content-padding-small:						10px;

$sidebar-link-padding-vertical:				12px;
$sidebar-link-padding-horizontal:			$content-padding-large;

// Navbar
$navbar-color:								#fff;




/**
 * Color Vairables
 */
// Primary
$color-primary-1:							#65A6CC;
$color-primary-2:							#398DBD;
$color-primary-3:							#2380B6;
$color-primary-4:							#0E74AF;
$color-primary-5:							#0D6AA0;
$color-primary-6:							#0B5580;

// Danger
$color-danger-1:							#FF9C9C;
$color-danger-2:							#FF7474;
$color-danger-3:							#FF4D4D;
$color-danger-4:							#FF3939;
$color-danger-5:							#FF2626;
$color-danger-6:							#D12020;

// Success
$color-success-1:							#8EBE86;
$color-success-2:							#69A95E;
$color-success-3:							#569E4A;
$color-success-4:							#3C7D30;
$color-success-5:							#2D7D1F;
$color-success-6:							#1E5315;

// Warning
$color-warning-1:							#FAB68E;
$color-warning-2:							#F99E69;
$color-warning-3:							#F89256;
$color-warning-4:							#F78643;
$color-warning-5:							#F77A31;
$color-warning-6:							#E16F2D;

// Info
$color-info-1:								#89AEB3;
$color-info-2:								#67979E;
$color-info-3:								#468189;
$color-info-4:								#40767D;
$color-info-5:								#3A6A71;
$color-info-6:								#2D5358;


// Brand Colors
$brand-primary:								$color-primary-4;
$brand-success:								$color-success-4;
$brand-info:								$color-info-4;
$brand-warning:								$color-warning-4;
$brand-danger:								$color-danger-4;
$dark-hover:								rgba(0,0,0,0.1);


// Variable
$white:										rgba(255,255,255,1);



/**
 * Media Breakpoints
 */
$breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;
