@mixin fa_content($code, $weight: 900) {
	content: unquote("'") + unquote(str-insert("f#{$code}","\\", 1)) + unquote("'");
	font-family: $font-awesome;
	font-style: normal;
	font-variant: normal;
	font-weight: $weight;
	text-rendering: auto;
}

@mixin fa_font($weight: 900) {
	font-family: $font-awesome;
	font-style: normal;
	font-variant: normal;
	font-weight: $weight;
	text-rendering: auto;
}