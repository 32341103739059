#toast-container {
	position: fixed;
	z-index: 1000000;

	* {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	> div {
		position: relative;
		overflow: hidden;
		margin: 0 0 6px;
		padding: 15px 15px 15px 50px;
		width: 300px;
		-moz-box-shadow: 0 0 3px #999;
		-webkit-box-shadow: 0 0 3px #999;
		box-shadow: 0 0 3px #999;
		color: #fff;
		opacity: .9;
		-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
		filter: alpha(opacity=90);

		&:hover {
			-moz-box-shadow: 0 0 4px #999;
			-webkit-box-shadow: 0 0 4px #999;
			box-shadow: 0 0 4px #999;
			opacity: 1;
			-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
			filter: alpha(opacity=100);
			cursor: pointer;
		}

		@include media-breakpoint-down('xs') {
			padding: 8px 8px 8px 50px;
			width: 11em;
		}

		@include media-breakpoint-between('xs', 'sm') {
			padding: 8px 8px 8px 50px;
			width: 18em;
		}

		@include media-breakpoint-between('sm', 'md') {
			padding: 15px 15px 15px 50px;
			width: 25em;
		}
	}

	&.toast-top-center {
		top: 0;
		right: 0;
		width: 100%;

		> div {
			width: 300px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&.toast-bottom-center {
		bottom: 0;
		right: 0;
		width: 100%;

		> div {
			width: 300px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&.toast-top-full-width {
		top: 0;
		right: 0;
		width: 100%;

		> div {
			width: 96%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&.toast-bottom-full-width {
		bottom: 0;
		right: 0;
		width: 100%;

		> div {
			width: 96%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&.toast-top-left {
		top: 12px;
		left: 12px;
	}

	&.toast-top-right {
		top: 12px;
		right: 12px;
	}

	&.toast-bottom-right {
		right: 12px;
		bottom: 12px;
	}

	&.toast-bottom-left {
		bottom: 12px;
		left: 12px;
	}

	.toast-title {
		font-weight: bold;
	}

	.toast-message {
		-ms-word-wrap: break-word;
		word-wrap: break-word;

		a,
		label {
			color: #fff;
		}
	}

	.toast-close-button {
		position: relative;
		right: -0.3em;
		top: -0.3em;
		float: right;
		font-size: 20px;
		font-weight: bold;
		color: #fff;
		-webkit-text-shadow: 0 1px 0 #fff;
		text-shadow: 0 1px 0 #fff;
		opacity: .8;
		-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
		filter: alpha(opacity=80);

		&:hover,
		&:focus {
			color: #000;
			text-decoration: none;
			cursor: pointer;
			opacity: .4;
			-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
			filter: alpha(opacity=40);
		}

		@include media-breakpoint-down('xs') {
			right: -0.2em;
			top: -0.2em;
		}

		@include media-breakpoint-between('xs', 'sm') {
			right: -0.2em;
			top: -0.2em;
		}
	}

	button.toast-close-button {
		padding: 0;
		cursor: pointer;
		background: transparent;
		border: 0;
		-webkit-appearance: none;
	}

	.toast {
		background-color: $toast-bg-color;

		&::before {
			@include fa_font();
			position: fixed;
			font-size: 24px;
			line-height: 24px;
			float: left;
			color: #fff;
			padding-right: .5em;
			margin: auto .5em auto -1.5em;
		}
	}

	.toast-info {
		background-color: $toast-info-bg-color;

		&::before {
			content: "\f129";
		}
	}

	.toast-success {
		background-color: $toast-success-bg-color;

		&::before {
			content: "\f00C";
		}
	}

	.toast-error {
		background-color: $toast-error-bg-color;

		&::before {
			content: "\f071";
		}
	}

	.toast-warning {
		background-color: $toast-warning-bg-color;

		&::before {
			content: "\f0e7";
		}
	}

	.toast-progress {
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 4px;
		background-color: #000;
		opacity: .4;
		-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
		filter: alpha(opacity=40);
	}
}