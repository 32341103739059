a, a:hover, a:focus {
	text-decoration: none;
}

textarea {
	resize: none;
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.smaller {
	font-size: 10px;
}

.pagination {
	justify-content: center;
}

.btn-group-xs > .btn, .btn-xs {
	padding: 1px 5px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}

td.btn-group, td.btn-group-vertical {
	display: table-cell;
}